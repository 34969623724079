import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import Navbar from "../components/Navbar";
import FamilyAgency from "../components/FamilyAgency";
import BannerFamilyTravel from "../components/BannerfamilyTravel";
import FamilyContact from "../components/FamilyContact";
import PlanningTrip from "../components/PlanningTrip";
import Clients from "../components/Clients";
import Footer from "../components/Footer";
import { fetchData } from "../services/api";

interface PlanningTripData {
  headingplanning: string;
  paragraph: string;
}

interface MetaData {
  metaKeyword: string;
  metaDescription: string;
  metaTitle: string;
}

const FamilyTravel: React.FC = () => {
  const [planningTripData, setPlanningTripData] = useState<PlanningTripData>({
    headingplanning: "",
    paragraph: "",
  });

  const [metaData, setMetaData] = useState<MetaData>({
    metaKeyword: "",
    metaDescription: "",
    metaTitle: "",
  });

  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchPlanningTripData = async () => {
      try {
        const response = await fetchData<{ whereTo: PlanningTripData[] }>({
          url: "pages/get",
          data: { pageId: 2 },
        });

        const whereToData = response?.data?.whereTo?.[0];
        if (whereToData) {
          setPlanningTripData(whereToData);
        } else {
          throw new Error("No whereTo data found.");
        }
      } catch (error) {
        console.error(error);
        setError("Failed to load Planning Trip data.");
      }
    };

    fetchPlanningTripData();
  }, []);

  useEffect(() => {
    const fetchMetaData = async () => {
      try {
        const response = await fetchData<{
          meta_keyword: string;
          meta_description: string;
          meta_title: string;
        }>({
          url: "pages/get",
          data: { pageId: 2 },
        });

        if (response?.data) {
          const { meta_keyword, meta_description, meta_title } = response.data;

          setMetaData({
            metaKeyword:
              meta_keyword ||
              "Family vacations, Aurora travel agency, custom travel planning",
            metaDescription:
              meta_description ||
              "Plan the perfect family vacation with Travel N’ Relax, Aurora's top-rated family travel agency. Tailored trips, expert advice, and memorable experiences.",
            metaTitle: meta_title,
          });
        } else {
          throw new Error("Metadata not found for the page.");
        }
      } catch (error) {
        console.error(error);
        setError("Failed to load metadata.");
      }
    };

    fetchMetaData();
  }, []);

  return (
    <div>
      <Helmet>
        <title>{metaData.metaTitle}</title>
        <meta name="keywords" content={metaData.metaKeyword} />
        <meta name="description" content={metaData.metaDescription} />
        <meta name="title" content={metaData.metaTitle} />
        <link rel="canonical" href="https://travelnrelax.com/family-travel/" />
      </Helmet>

      <Navbar heading="DENVER'S TOP RATED FAMILY TRAVEL AGENCY" subHeading="" />
      <FamilyAgency />
      <BannerFamilyTravel />
      <FamilyContact />

      {error ? (
        <p className="text-red-600 text-center py-4">{error}</p>
      ) : (
        <PlanningTrip
          headingplanning={
            planningTripData.headingplanning ||
            "Let's Plan Your Perfect Family Trip"
          }
          paragraph={
            planningTripData.paragraph ||
            "We'll help craft unforgettable family travel experiences."
          }
        />
      )}

      <Clients />
      <Footer />
    </div>
  );
};

export default FamilyTravel;

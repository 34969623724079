import React, { useState, useEffect } from "react";
import PlanningTrip from "../components/PlanningTrip";
import Clients from "../components/Clients";
import Footer from "../components/Footer";
import NavMenu from "../components/NaveMenu";
import ContactSection from "../components/ContactSection";
import PromoBanner from "../components/PromoBanner";
import MonthGallery from "../components/MonthGallery";
import { Helmet } from "react-helmet";
import { fetchData } from "../services/api";

const Month: React.FC = () => {
  const [metaData, setMetaData] = useState({
    pageTitle: "",
    metaKeyword: "",
    metaDescription: "",
    metaTitle: "",
  });

  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchMetaData = async () => {
      try {
        const response = await fetchData<{
          meta_keyword: string;
          meta_description: string;
          meta_title: string;
        }>({
          url: "pages/get",
          data: { pageId: 16 },
        });

        if (response?.data) {
          const { meta_keyword, meta_description, meta_title } = response.data;

          setMetaData({
            pageTitle: meta_title,
            metaKeyword: meta_keyword,
            metaDescription: meta_description,
            metaTitle: meta_title,
          });
        } else {
          throw new Error("Metadata not found for the page.");
        }
      } catch (error) {
        console.error(error);
        setError("Failed to load metadata.");
      }
    };

    fetchMetaData();
  }, []);

  return (
    <div>
      <Helmet>
        <title>{metaData.metaTitle}</title>
        <meta name="title" content={metaData.metaTitle} />
        <meta name="keywords" content={metaData.metaKeyword} />
        <meta name="description" content={metaData.metaDescription} />
        <link rel="canonical" href="https://travelnrelax.com/month/" />
      </Helmet>

      {error && <p style={{ color: "red", textAlign: "center" }}>{error}</p>}

      <NavMenu />
      <MonthGallery />
      <PromoBanner />
      <ContactSection />
      <PlanningTrip
        headingplanning="Where to next?"
        paragraph="We’ll create a vacation you’ll remember forever."
      />
      <Clients />
      <Footer />
    </div>
  );
};

export default Month;
